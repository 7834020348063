/*
 * © 2021 Thoughtworks, Inc.
 */

import { createRoot } from 'react-dom/client'
import { Root } from './Root'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<Root />)
